export default {
  text: '#111',
  primary: '#EBBE29',
  secondary: '#257744',
  tertiary: '#111',
  background: '#f7f7f7',
  backgroundSecondary: '#fff',
  light: '#FFF',
  dark: '#1117'
}
