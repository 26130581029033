export default {
  heading: 'Playfair Display, serif',
  body: 'Playfair Display, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Questrial, cursive',
  display2: 'Charm',
  display3: 'Charm',
  googleFonts: [
    'Charm:100,200,300,400,500,600,700,800,900',
    'Playfair Display:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Questrial'
  ]
  // customFamilies: [''],
  // customUrls: [''],
}
